'use client';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import {
	Alert,
	Container,
	FormGroup,
	Grid,
	Paper,
	TextField,
	Tooltip,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import Image from 'next/image';
import { encryptAES, useClientTranslation } from '@negotium/common';
import { GenericServices } from '@negotium/common';
import { AxiosError } from 'axios';
import { useParams, useRouter } from 'next/navigation';
import { IAuth } from '@negotium/models';

import useAuthFormData from './hooks/useAuthFormData';

const ContainerLogo = styled('div')({
	width: '200px',
	margin: 'auto',
});

export interface ISignInProps {
	lng: string;
}

export default function SignIn({ lng }: ISignInProps) {
	const { t } = useClientTranslation(lng, ['common']);

	const [loadingAuth, setLoadingAuth] = useState(false);
	const [messagesAlert, setMessageAlert] = useState<string>('');
	const { urlCallback } = useParams();
	const router = useRouter();

	const initialValues = {
		email: '',
		password: '',
	};

	const onSubmit = async (values: IAuth): Promise<void> => {
		try {
			setLoadingAuth(true);
			let password: string = values.password;
			if (values?.password && process.env.NEXT_PUBLIC_ENCRYPT_KEY) {
				password = encryptAES(
					values.password,
					process.env.NEXT_PUBLIC_ENCRYPT_KEY
				);
			}
			await GenericServices('login', [], {
				email: values?.email,
				password: password,
			});
			setLoadingAuth(false);
			router.push(urlCallback ? String(urlCallback) : `/admin`);
		} catch (err) {
			if (err instanceof AxiosError) {
				if (err?.response?.status === 403) {
					setMessageAlert('userOrPassInc');
				} else if (err?.response?.status === 405) {
					setMessageAlert('userNoActive');
				} else {
					setMessageAlert(err?.response?.data?.message);
				}
			}
			setLoadingAuth(false);
		}
	};

	const { formik, isLoading } = useAuthFormData(initialValues, onSubmit, lng);

	return (
		<Container
			maxWidth={false}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
				backgroundImage: 'url(/static/trama.jpg)',
				backgroundSize: 'cover',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'center center',
			}}
		>
			<Paper
				elevation={12}
				sx={{
					borderRadius: '1rem',
				}}
			>
				<Container
					maxWidth='xs'
					sx={{
						backdropFilter: 'blur(5px)',
						padding: '1rem',
						backgroundColor: 'rgba(255, 255, 255, 0.792)',
						borderRadius: '1rem',
					}}
				>
					<form onSubmit={formik.handleSubmit}>
						<Grid container rowSpacing={2}>
							<Grid item xs={12}>
								<Tooltip title={`${process.env.NEXT_PUBLIC_NAME}` || ''}>
									<ContainerLogo>
										<Link href={`/${lng}`}>
											<Image
												alt={process.env.NEXT_PUBLIC_NAME || ''}
												src='/static/logo_lacuadra.png'
												width={512}
												height={512}
												style={{ width: '100%', height: 'auto' }}
											/>
										</Link>
									</ContainerLogo>
								</Tooltip>
							</Grid>
							<Grid item xs={12}>
								<Typography
									variant='ExplicationTextBold'
									textAlign='center'
									marginY={2}
									fontSize='clamp(14px, 3vw, 1.6rem)'
								>
									{t('signIn')}
								</Typography>
							</Grid>
							{messagesAlert && (
								<Grid item xs={12}>
									<Alert severity='warning'>{t(messagesAlert)}</Alert>
								</Grid>
							)}
							<Grid item xs={12}>
								<FormGroup>
									<TextField
										size='small'
										label={t('email')}
										id='email'
										placeholder='Eg. johndoe@gmail.com'
										value={formik.values.email}
										onChange={(e) => formik.handleChange(e)}
										onBlur={formik.handleBlur}
										error={formik.touched.email && Boolean(formik.errors.email)}
										helperText={
											formik.touched.email &&
											formik.errors.email &&
											t(String(formik.errors.email))
										}
										fullWidth={false}
										margin='dense'
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={12}>
								<FormGroup>
									<TextField
										label={t('password')}
										size='small'
										id='password'
										type='password'
										placeholder='*********'
										value={formik.values.password}
										onChange={(e) => formik.handleChange(e)}
										onBlur={formik.handleBlur}
										error={
											formik.touched.password && Boolean(formik.errors.password)
										}
										helperText={
											formik.touched.password &&
											formik.errors.password &&
											t(String(formik.errors.password))
										}
										fullWidth={false}
										margin='dense'
									/>
								</FormGroup>
							</Grid>
							<Grid
								item
								xs={12}
								sx={{
									marginTop: '20px',
								}}
							>
								<Button
									type='submit'
									autoFocus
									variant='AcceptDialog'
									disabled={isLoading || loadingAuth}
								>
									{t('signIn')}
								</Button>
							</Grid>
						</Grid>
					</form>
				</Container>
			</Paper>
		</Container>
	);
}
